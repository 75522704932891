<template>

  <FocusTrap>
    <div class="card">

    <div class="card-header header-elements-inline" style="background-color: lightgrey;padding: 12px;" >
      <h5 class="card-title">Color Information</h5>
      <div class="header-elements">
        <div class="list-icons">
          <a class="list-icons-item" data-action="reload"></a>
          <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
        </div>
      </div>
    </div>

    <div class="card-body">
      <p/>
      <form action="#">

        <div class="form-group row">
          <label class="col-md-2 col-form-label font-weight-semibold">Name:</label>
          <div class="col-md-10">
            <input id="txtname" type="text" class="form-control" placeholder="Enter Color Name here..." maxlength="100" v-if="colorchart" v-model="colorchart.name" autocomplete="off" >
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label font-weight-semibold">Pigments:</label>
          <div class="col-md-10">

            <div class="table-responsive"  style="padding-bottom: 0px;">

              <table class="table table-no-bordered" style="padding-bottom: 0px;">
                <thead style="background-color: lightgrey">
                  <tr>
                  <th style="width:50px;">S.No</th>
                  <th >Pigment</th>
                  <th style="width:100px; text-align: right;">Qty</th>
                  <th style="width: 30px;">Action</th>
                </tr>
                </thead>
                <tbody v-model="colorchart" v-for="(pigment,index) in colorchart.chart" style="padding: 0px">

                  <tr style="padding: 0px">
                    <td style="text-align: center;padding: 0px;"> {{index + 1}} </td>
                    <td style="padding: 0px">
                      <select class="form-control" v-model="pigment.pigment_id" >
                        <option v-for="pig in pigments" v-bind:value="pig.id">
                          {{ pig.name }}
                        </option>
                      </select>
                    </td>

                    <td style="padding: 0px">
                      <input type="number" class="form-control text-right" placeholder="Weight In Kgs" max="100" v-if="pigment" v-model="pigment.weight" />
                    </td>

                    <td class="text-center" style="padding: 0px">
                      <button name="btnRemoveRow"   type="button" class="btn border-warning text-warning-600 btn-flat btn-icon btn-rounded legitRipple btnRemoveRow" @click="removeRow(index)" tabindex="-1" ><i class="icon-eraser"></i></button>
                    </td>
                  </tr>

                </tbody>
                <tfoot>
                  <tr>
                    <td>
                      <button id="btnAddRow" type="button" class="btn border-success text-success-800 btn-flat btn-icon btn-rounded legitRipple" @click="addRow">
                        <i class="icon-plus3"></i>
                      </button>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                </tfoot>
              </table>
            </div>

          </div>
        </div>

      </form>
    </div>

    <div class="card-footer"  style="padding: 10px;">
      <div class="text-right" >
        <button type="button" class="btn btn-primary" @click="saveDocument" >Save<i class="icon-paperplane ml-2"></i></button>
      </div>
    </div>
  </div>
  </FocusTrap>
</template>

<script>
import FabButton from '@/components/core/FabButton.vue'
import {userService} from '@/store/auth-header.js'
export default {
  name: 'ColorChartForm',
  data () {
    return {
      colorchart: JSON.parse('{"id":0,"status":"Active","type":0,"name":"","weight":0,"chart":[]}'),
      pigments: [],
    }
  },
  component: {
    FabButton
  },
  props: {
    mycolorchart: {
      type: Object,
      default: () => JSON.parse('{"id":0,"status":"Active","type":0,"name":"","weight":0,"chart":[]}')
    }
  },
  beforeMount () {
    this.$data.colorchart = this.mycolorchart; // save props data to itself's data and deal with tms
  },
  created () {
    this.$data.colorchart = JSON.parse('{"id":0,"status":"Active","type":0,"name":"","weight":0,"chart":[]}');
  },
  mounted () {
    this.loadAll();
    $('#txtname').focus();
  },
  methods: {
    closeWindow() {
      this.$emit('colorchart_window_closed');
    },
    loadAll(){
      this.loadPigments();
      this.$data.colorchart = this.mycolorchart;
    },
    addRow(){
      this.$data.colorchart.chart.push(JSON.parse('{"pigment_id":0,"pigment":{"id":0,"type":0,"name":""},"weight":0}'));
    },
    removeRow(index){
      if (index > -1) {
        this.$data.colorchart.chart.splice(index, 1);
      }
    },
    loadPigments(){
      const self = this;
      const requestOptions = {
        method:  'GET',
        mode:"cors",
        headers: userService.authHeader(),
      };


      self.$data.pigments = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/pigments`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          if(resp.data != null){
            self.$data.pigments = resp.data;
          }
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {

      });
    },
    saveDocument(){
      const self = this;

      if(self.$data.colorchart.name.trim().length < 1){
        alert("Invalid Name");
        return
      }

      self.$data.colorchart.chart.forEach(function (pigment) {
        pigment.weight = parseFloat(pigment.weight);
      });

      console.log(JSON.stringify(self.$data.colorchart));

      const requestOptions = {
        method:  (self.$data.colorchart.id == 0 ? 'POST' : 'PUT'),
        mode:"cors",
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.colorchart)
      };



      fetch(`${process.env.VUE_APP_ROOT_API}api/colorchart`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          swal ( { title: "Success!" ,  text: resp.msg, type:  "success", timer:1500} );
          // self.colorchart = JSON.parse('{"id":0,"status":"Active","type":0,"name":"","weight":0,"chart":[]}');
          self.$emit('colorchart_saved', resp.data);
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      });
    }
  }
}
</script>

<style scoped>
input:focus {
  background: #feff00;

}

textarea:focus {
  background: #feff00;

}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00;
  color: #0a0a0a!important;
}
</style>
